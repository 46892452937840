
    import * as React from 'react';
    import Card from '@mui/material/Card';
    import CardActions from '@mui/material/CardActions';
    import CardContent from '@mui/material/CardContent';
    import CardMedia from '@mui/material/CardMedia';
    import Button from '@mui/material/Button';
    import Typography from '@mui/material/Typography';
    
    export default function MediaCard6() {
        //This is the card that will be used to display the languages
        //the image in the card will be the logo resized to 512x512
        //This one is for SQL
      return (
        <Card sx={{  minWidth: 345 }}>
          <CardMedia
            sx={{ padding: "5em 3em 3em 3em", objectFit: "contain", height: "30px", width: "80px", margin: "auto" , backgroundColor: "white", borderRadius: "0%" ,marginTop:2    }}
            image= "https://brandeps.com/logo-download/M/MySQL-logo-vector-01.svg"
            title="SQL"
    
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
             SQL
            </Typography>
            <Typography variant="body2" color="text.secondary">
            SQL (Structured Query Language) is a language designed to allow both technical and non-technical users query, manipulate, and transform data from a relational database. And due to its simplicity, SQL databases provide safe and scalable storage for millions of websites and mobile applications.
            </Typography>
    
          </CardContent>
          <CardActions>
        
            <Button size="small"  target={"_blank"} color='warning' href='https://www.mysql.com/'>Learn More</Button>
          </CardActions>
        </Card>
      );
    }
    